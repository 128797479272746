<script setup>
const props = defineProps({
    options: {
        type: Array,
        required: true,
    },
    modelValue: {
        type: Object,
        required: true,
    },
    optionAttribute: {
        type: String,
        required: true,
    }
})
const isOpen = ref(false)
const emit = defineEmits(["update:modelValue"]);
const updateValue = (option) => {
    emit('update:modelValue', option);
}

// Reference to the dropdown element
const dropdown = ref(null);

// Function to close the dropdown if clicked outside
const closeDropdownOnOutsideClick = (event) => {
    if (dropdown.value && !dropdown.value.contains(event.target)) {
        isOpen.value = false;
    }
};

// Add event listener on mount and clean up on unmount
onMounted(() => {
    document.addEventListener('click', closeDropdownOnOutsideClick);
});
</script>

<template>
    <div class="relative inline-block w-full">
        <button
            class="w-full flex justify-center items-center gap-2  bg-transparent rounded-md pl-1 pr-4 py-2 text-left outline-none  transition ease-in-out duration-150"
            @click="isOpen = !isOpen" ref="dropdown">
            <span>{{ modelValue[optionAttribute] || 'Select an option' }}</span>
            <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 10l5 5 5-5H7z" />
            </svg>
        </button>

        <div v-if="isOpen"
            class="absolute z-10 mt-1 -left-2 w-full bg-white  border-gray-300 rounded-md shadow-none border shadow-black drop-shadow-xl"
            @click.outside="isOpen = false">
            <ul class="max-h-60 overflow-y-auto">
                <template v-for="option in options">
                    <template v-if="option[props.optionAttribute] != props.modelValue[props.optionAttribute]">
                        <li :key="option[optionAttribute]" @click="updateValue(option)"
                            class="cursor-pointer text-gray-950 hover:bg-gray-100 transition duration-150">
                            <slot name="option" :option="option">
                                {{ option[optionAttribute] }}
                            </slot>
                        </li>
                    </template>
                </template>

            </ul>
        </div>
    </div>
</template>
